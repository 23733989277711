import commonConst from '../constants/commonConst';

export const saveToLocalStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(process.env.REACT_APP_ENV + '_' + commonConst.GB + '_' + key, serializedValue);
    } catch (e) {
        console.log(e);
    }
};

export const loadFromLocalStorage = (key) => {
    try {
        const serializedValue = localStorage.getItem(process.env.REACT_APP_ENV + '_' + commonConst.GB + '_' + key);
        if (serializedValue === null) return undefined;
        return JSON.parse(serializedValue);
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(process.env.REACT_APP_ENV + '_' + commonConst.GB + '_' + key);
};
