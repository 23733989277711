import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { authHelper } from '../utils/authHelper';
import { PermissionSideMenuDisplay } from '../enumerations/permissionEnum';

const Sidebar = (props) => {
    const [sideMenu, setSideMenu] = useState([]);
    const [currentParentMenu, setCurrentParentMenu] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        SetupSideMenu();
        InitSideMenu();
    }, []);

    const SetupSideMenu = async () => {
        let menu = authHelper.getMenuPermission();
        setSideMenu(menu);
    };

    const InitSideMenu = async () => {
        setCurrentParentMenu(window.location.pathname.split('/')[1]);
    };

    const handleClick = () => {
        let collapse = !isCollapsed;
        setIsCollapsed(collapse);
        let content = document.getElementById('collapsible_panel_content');
        let mbcdt = document.getElementById('mbc-dt');
        let msdt = document.getElementById('ms-dt');
        if (content !== undefined && content !== null) content.style.maxWidth = props.screenwidth - (collapse ? 152 : 272) + 'px';

        if (mbcdt !== undefined && mbcdt !== null) mbcdt.style.maxWidth = props.screenwidth - (collapse ? 152 : 272) + 'px';
        if (msdt !== undefined && msdt !== null) msdt.style.maxWidth = props.screenwidth - (collapse ? 152 : 272) + 'px';
    };

    const handleMenuItemClick = (e, data) => {
        setCurrentParentMenu(data);
    };

    return (
        <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${isCollapsed ? ' toggled' : ''}`} id="accordionSidebar">
            <div className="sidebar-brand d-flex align-items-center justify-content-center">
                <a href="index.html">
                    <div className="sidebar-brand-text logo">Ghostbuster</div>
                </a>
                <div className="text-center d-none d-md-inline sidebar_tooglewrap">
                    <button className="rounded-circle border-0" id="sidebarToggle" onClick={() => handleClick()}></button>
                </div>
            </div>

            <hr className="sidebar-divider my-0" />

            <li className={`nav-item ${currentParentMenu === 'dashboard' ? 'active' : ''}`} onClick={(e) => handleMenuItemClick(e, 'dashboard')}>
                <NavLink className="nav-link" to="/dashboard">
                    <i className="fas fa-fw fa fa-tachometer"></i>
                    <span>Dashboard</span>
                </NavLink>
            </li>
            <hr className="sidebar-divider" />

            <RenderSideMenu props={props} currentParentMenu={currentParentMenu} isCollapsed={isCollapsed} sideMenu={sideMenu} handleMenuItemClick={handleMenuItemClick} />

            <hr className="sidebar-divider d-none d-md-block" />
        </ul>
    );
};

const RenderMenuItem = ({ child, handlePopupClick }) => {
    if (child.remark === 'popup')
        return (
            <NavLink id={`child${child.id}Popup`} key={`child${child.id}Popup`} className="collapse-item" to={`${child.url}`} onClick={(e) => handlePopupClick(e, child.url)}>
                {PermissionSideMenuDisplay[child.id]}
            </NavLink>
        );
    else {
        return (
            <NavLink
                id={`child${child.id}`}
                key={`child${child.id}`}
                className="collapse-item"
                to={{
                    pathname: `${child.url}`,
                    onFocusId: `child${child.id}`,
                }}
            >
                {PermissionSideMenuDisplay[child.id]}
            </NavLink>
        );
    }
};

const RenderSideMenu = (props) => {
    let { sideMenu, isCollapsed, handleMenuItemClick, currentParentMenu } = props;
    const [randomNo, setRandomNo] = useState(0);

    const handlePopupClick = (e, pageUrl) => {
        e.preventDefault();
        if (randomNo > 100) setRandomNo(0);
        else setRandomNo(randomNo + 1);

        window.open(pageUrl, 'alert' + randomNo, 'toolbar=0,status=0,width=1700,height=800');
    };

    const containerStyle = {
        maxHeight: isCollapsed ? '0' : '200px',
        overflowY: 'auto',
    };

    const RenderMenu = () => {
        return sideMenu
            .filter((x) => x.level === 1)
            .map((parent) => {
                const isMenuActive = currentParentMenu === parent.url;
                const isSubMenuVisible = !isCollapsed && isMenuActive;
                return !parent.remark.includes('nosubmenus') ? (
                    <li id={`${parent.id}Menu`} key={`${parent.id}Menu`} className={`nav-item ${isMenuActive ? 'active' : ''}`} onClick={(e) => handleMenuItemClick(e, parent.url)}>
                        <a
                            id={`${parent.id}Link`}
                            className={`nav-link ${isSubMenuVisible ? '' : 'collapsed'}`}
                            href="##"
                            data-toggle="collapse"
                            data-target={`#${parent.id}Div`}
                            aria-expanded={isSubMenuVisible ? 'true' : 'false'}
                            aria-controls={`${parent.id}Div`}
                        >
                            <i className={`fas fa-fw ${parent.remark}`}></i>
                            <span>{PermissionSideMenuDisplay[parent.id]}</span>
                            {isSubMenuVisible}
                        </a>
                        <div id={`${parent.id}Div`} className={`collapse ${isSubMenuVisible ? 'show' : ''}`} aria-labelledby={`${parent.id}Link`} data-parent="#accordionSidebar">
                            <div style={containerStyle} className="menuScroll bg-white py-2 collapse-inner rounded">
                                {sideMenu
                                    .filter((x) => x.level === 2 && x.pID === parent.id)
                                    .map((child, index) => {
                                        return <RenderMenuItem key={index} child={child} handlePopupClick={handlePopupClick} />;
                                    })}
                            </div>
                        </div>
                    </li>
                ) : (
                    <li id={`${parent.id}Menu`} key={`${parent.id}Menu`} className={`nav-item ${isMenuActive ? 'active' : ''}`} onClick={(e) => handleMenuItemClick(e, parent.url)}>
                        <NavLink className="nav-link" to={`/${parent.url}`}>
                            <i className={`fas fa-fw ${parent.remark}`}></i>
                            <span>{PermissionSideMenuDisplay[parent.id]}</span>
                        </NavLink>
                    </li>
                );
            });
    };
    return <RenderMenu />;
};

RenderSideMenu.propTypes = {
    sideMenu: PropTypes.array.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired,
    currentParentMenu: PropTypes.string.isRequired,
};
RenderMenuItem.propTypes = {
    child: PropTypes.object.isRequired,
    handlePopupClick: PropTypes.func.isRequired,
};
Sidebar.propTypes = {
    screenwidth: PropTypes.number.isRequired,
};
export default Sidebar;
