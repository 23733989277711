const commonConst = require('../constants/commonConst');

exports.onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

exports.checkArrayIsNullOrEmpty = (arr) => {
    if (arr !== undefined && arr !== null && arr.length > 0) return false;

    return true;
};

exports.formulateToJsonArray = (arr) => {
    let value = '';
    try {
        value = arr.filter((x) => {
            return !(x.value === null || x.value === '' || x.value === undefined || x.value?.length === 0 || (x.value?.length === undefined && x.value === false));
        });
    } catch (e) {
        console.log(e);
    }
    return value;
};

exports.formulateToJsonArrayWithNull = (arr) => {
    let value = '';
    try {
        value = arr.filter((x) => {
            return !(x.value === null || x.value === '' || x.value?.length === 0 || (x.value?.length === undefined && x.value === false));
        });
    } catch (e) {
        console.log(e);
    }
    return value;
};

exports.formulateToJsonArrayWithEmptyArray = (arr) => {
    let value = '';
    try {
        value = arr.filter((x) => {
            return !(x.value === '' || x.value === '[]' || x.value === null || x.value === 'null' || x.value?.length === 0 || (x.value?.length === undefined && x.value === false));
        });
    } catch (e) {
        console.log(e);
    }
    return value;
};

exports.formulateSelectedAllOptions = (selectedOption) => {
    let includedAll = selectedOption !== null && selectedOption !== [] && selectedOption.map((x) => x.label).includes(commonConst.ALL);
    return includedAll ? [] : selectedOption.map((x) => x.value);
};

exports.formulateSaveDBSelectedAllOptions = (selectedOption, formatInt = false) => {
    if (selectedOption === undefined) return [];

    let includedAll = selectedOption !== null && selectedOption !== [] && selectedOption.map((x) => x.label).includes(commonConst.ALL);
    if (formatInt) return includedAll ? -1 : selectedOption.map((x) => parseInt(x.value));
    return includedAll ? -1 : selectedOption.map((x) => x.value);
};

exports.formulateInsertSelectedAllOptions = (selectedOption) => {
    let includedAll = selectedOption !== null && selectedOption !== [] && selectedOption.map((x) => x.label).includes(commonConst.ALL);
    return includedAll ? selectedOption.filter((x) => x.label !== commonConst.ALL).map((x) => parseInt(x.value)) : selectedOption.map((x) => parseInt(x.value));
};

exports.RemoveArrayElementByObj = (arr, key, tobeRemove) => {
    const indexOfObject = arr.findIndex((object) => {
        return object[key] === parseInt(tobeRemove);
    });

    arr.splice(indexOfObject, 1);

    return arr;
};

exports.RemoveArrayElementByArray = (arr, key, tobeRemove) => {
    for (let i = 0; i < tobeRemove.length; i++) {
        arr = this.RemoveArrayElementByObj(arr, key, tobeRemove[i]);
    }
    return arr;
};

exports.AddArrayElementByObj = (arr, key, toBeAdd) => {
    const indexOfObject = arr.findIndex((object) => {
        return object[key] === parseInt(toBeAdd);
    });
    return arr[indexOfObject];
};

exports.AddArrayElementByArray = (arr, key, toBeAdd) => {
    let result = [];
    for (let i = 0; i < toBeAdd.length; i++) {
        result.push(this.AddArrayElementByObj(arr, key, toBeAdd[i]));
    }
    return result;
};

exports.GroupBy = (items = [], key = '') => {
    let resultObj = {};
    for (let i = 0; i < items.length; i++) {
        let groupKey = items[i][key];
        if (resultObj[groupKey] === undefined) resultObj[groupKey] = [items[i]];
        else {
            let newList = resultObj[groupKey].concat(items[i]);
            resultObj[groupKey] = newList;
        }
    }
    return resultObj;
};
