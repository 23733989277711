import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Footer extends Component {
    render() {
        return (
            <footer className="sticky-footer">
                <Container className="my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; 2020</span>
                    </div>
                </Container>
            </footer>
        );
    }
}

export default Footer;
