import { loadFromLocalStorage } from './storageHelper';
import storageConst from '../constants/storage';
import { authHelper } from './authHelper';
const defaultOptions = {
    //mode: 'cors',
    cache: 'no-cache',
    //credentials: "same-origin",
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    },
};

/**
 * get request
 * @param {String} url request url
 */
function get(url) {
    return send(
        url,
        Object.assign(defaultOptions, {
            method: 'GET',
            body: null,
        }),
    );
}

/**
 * post request with JSON data
 * @param {String} url request url
 * @param {JSON} data request json data
 */
function post(url, data) {
    return send(
        url,
        Object.assign(defaultOptions, {
            method: 'POST',
            body: JSON.stringify(data),
        }),
    );
}

function postFormData(url, data) {
    return send(
        url,
        Object.assign(
            {
                cache: 'no-cache',
                headers: {},
            },
            {
                method: 'POST',
                body: data,
            },
        ),
    );
}

/**
 * this is a flexibale http request method via fetch
 * @param {String} url request url
 * @param {JSON} options request options
 */
function send(url, options) {
    const currentUser = loadFromLocalStorage(storageConst.CURRENT_USER);
    if (currentUser && currentUser.token) options.headers.Authorization = `Bearer ${currentUser.token}`;
    return fetch(url, options)
        .then(checkStatus)
        .then((response) => response.json());
}

async function checkStatus(response) {
    if (response.status > 402 && response.status < 404) {
        const apierror = loadFromLocalStorage(storageConst.AUTH_ERROR);
        const err = new Error('Authentication error, Please login again');

        if (!apierror) {
            alert(err);
            err.response = 403;
            authHelper.logout();
            throw err;
        }
    } else if (response.status === 200) {
        return response;
    }
    let responseBody = await response.json();
    const err = new Error(`${response.statusText} ${typeof responseBody === 'object' ? JSON.stringify(responseBody) : responseBody}`);
    err.response = response.status;
    throw err;
}

export { get, post, postFormData };
