import React from 'react';

class Loading extends React.Component {
    render() {
        return (
            <>
                <div className="animated--fade-in pt-1 text-center">
                    <div className="spinner-border text-primary text-center" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="animated--fade-in pt-1 text-center">Loading...</div>
            </>
        );
    }
}

export default Loading;
