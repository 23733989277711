/* eslint-disable react/prop-types */
import React, { Component, Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Loading from '../components/Common/Loading';

import routes from '../routes';

class Main extends Component {
    state = { width: window.innerWidth, height: window.innerHeight };
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    render() {
        return (
            <div id="wrapper" className="scrollarea_outer">
                <Sidebar screenwidth={this.state.width} onFocusId={this.props.location.onFocusId} />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <Container fluid style={{ height: '100%' }}>
                            <Suspense fallback={<Loading />}>
                                <Switch>
                                    {routes.map((route, idx) => {
                                        return route.component ? (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                render={(props) => <route.component {...props} {...route} screenwidth={this.state.width} screenheight={this.state.height} />}
                                            />
                                        ) : null;
                                    })}
                                    <Redirect from="/" to="/dashboard" />
                                </Switch>
                            </Suspense>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Main;
