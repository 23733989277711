import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { loadFromLocalStorage } from '../utils/storageHelper';
import storageConst from '../constants/storage';
import { cookies } from './authHelper';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const currentUser = loadFromLocalStorage(storageConst.CURRENT_USER);
            if (!currentUser || !currentUser.token) {
                // not logged in so redirect to login page with the return url
                cookies.remove('menu');
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                );
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);
